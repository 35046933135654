import React from 'react'
import './style.scss'

interface IProps {
  title: string
  align?: 'center' | 'left'
  children?: React.ReactNode
}

export default function ContentCard(props: IProps) {
  const { title, align = 'left' } = props

  return (
    <div className="com-content-card">
      <div
        className="com-content-card-title"
        style={{ textAlign: align === 'center' ? 'center' : 'left' }}
      >
        {title}
      </div>
      <div className="com-content-card-content">{props.children}</div>
    </div>
  )
}
