import './style.scss'

interface IProps {
  orderNo: string
}

export default function FeedBack(props: IProps) {
  const { orderNo = '' } = props
  console.log(orderNo)
  const onClick = () => {
    window.location.href =
      process.env.REACT_APP_SECRET_CODE === 'production'
        ? `https://inall.ijianshui.com/report?orderNo=${orderNo}`
        : `http://test-inall.ijianshui.com/report?orderNo=${orderNo}`
  }

  return (
    <div className="com-feedback" onClick={onClick}>
      <div className="icon" />
      <div className="title">客服</div>
    </div>
  )
}
