import cn from 'classnames'
import './style.scss'
interface IProps {
  name: string
  lock: boolean
}

export default function Report(props: IProps) {
  return (
    <div
      className={cn('com-report', {
        lock: props.lock,
      })}
    >
      <div className="com-report-title">{props.name}的一生分析报告</div>
      <div className="tags">
        <div className="tag t1" />
        <div className="tag t2" />
        <div className="tag t3" />
        <div className="tag t4" />
      </div>
      <div className="circles">
        <div className="circle c1">
          2023
          <br />
          运程
        </div>
        <div className="circle c2">
          一生
          <br />
          运程
        </div>
        <div className="circle c3">
          格局
          <br />
          解读
        </div>
        <div className="circle c4">
          婚姻
          <br />
          情感
        </div>
        <div className="circle c5">
          事业
          <br />
          财运
        </div>
        <div className="circle c6">
          健康
          <br />
          预警
        </div>
        <div className="circle c7">
          桃花
          <br />
          姻缘
        </div>
      </div>

      <div className="report-tip">多维度、全面分析你的一生命运，掌握人生重大机遇</div>
    </div>
  )
}
