import cn from 'classnames'
import React from 'react'
import Title from '../title'
import './style.scss'

interface IProps {
  title?: string
  children: React.ReactNode
  className?: string
}

export default function Card(props: IProps) {
  const { title, className } = props
  return (
    <div className={cn('com-card', className)}>
      {title && <Title title={title} />}{' '}
      <div className="content">
        <div className="inner">{props.children}</div>
      </div>
    </div>
  )
}
