import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PageIndex from './pages/index'
import PageOrder from './pages/order'
import PagePay from './pages/pay'
import PageResult from './pages/result'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PageIndex />} />
        <Route path="/pay" element={<PagePay />} />
        <Route path="/result" element={<PageResult />} />
        <Route path="/order" element={<PageOrder />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
