import './style.scss'

interface IProps {
  title: string
}

export default function Title(props: IProps) {
  return (
    <div className="com-title">
      <div className="inner">{props.title}</div>
    </div>
  )
}
