import { Search } from 'auto-libs'
import { Layout, Loading, Toast } from 'auto-ui'
import cn from 'classnames'
import { useEffect } from 'react'
import { useSetState } from 'react-use'
import Button from '../../components/button'
import Card from '../../components/card'
import Footer from '../../components/footer'
import Report from '../../components/report'
import http from '../../utils/http'
import { aliPay, wxPay } from '../../utils/pay'
import { getInfoInputAnimationEnd, setInfoInputAnimationEnd } from '../../utils/storage'
import { getCountDown, sleep } from '../../utils/time'
import FakeBubble from './components/fake-buble'
import './style.scss'
/* eslint-disable @typescript-eslint/indent */

// import Banner from './imgs/banner@2x.png'

// "bgp": "根丁丑、苗己卯、花辛巳、果癸未",
//         "wxys": "摆脱心结、善用现有资源创造最佳状况的这段日子",
//         "mzgj": "吉神格局，食神、偏财、正官、偏印",

interface IState {
  isAnimationEnd: boolean
  steps:
    | 'step1'
    | 'step2'
    | 'step3'
    | 'step4'
    | 'step5'
    | 'step6'
    | 'step7'
    | 'step8'
    | 'step9'
    | 'end'
  tjPanStep: string
  time: number
  payType: 'wechat' | 'alipay'
  alipayForm: any
  animationTitle: string
  processing: boolean
  tjCircle1: string
  tjCircle2: string
  tjCircle3: string
  tjCircleMasker: boolean
  loading: boolean
  userData: any
}

const stepsTitle = {
  step1: '正在录入命主信息',
  step2: '正在生成八字',
  step3: '正在计算你的财富格局',
  step4: '正在计算你的姻缘格局',
  step5: '正在计算你的健康格局',
  step6: '正在生成格局、五行旺度',
  step7: '正在生成大运、流年',
  step8: '正在生成你的八字命书',
  step9: '正在生成分析报告',
}

const PagePay = () => {
  const [state, setState] = useSetState<IState>({
    isAnimationEnd: false,
    time: 60 * 5 - 1,
    payType: 'wechat',
    alipayForm: null,
    tjPanStep: '',
    steps: 'end',
    animationTitle: '',
    processing: false,
    tjCircle1: '',
    tjCircle2: '',
    tjCircle3: '',
    tjCircleMasker: false,
    loading: false,
    userData: {},
  })
  const { orderNo, name, gender, birth } = Search.parse()
  let timer: any = null

  const countdown = (time: number) => {
    let _time = time
    timer = setTimeout(() => {
      --_time
      if (_time === 0) {
        clearTimeout(_time)
        return
      }

      setState({
        time: _time,
      })

      countdown(_time)
    }, 1000)
  }

  const onWechatPay = async (discount?: string) => {
    try {
      Loading()
      const result = await wxPay(orderNo, discount)
      window.location.href = result
    } catch (error: any) {
      Toast(error.message || '系统异常，请重试')
    } finally {
      Loading.hide()
    }
  }

  const onAlipay = async (discount?: string) => {
    try {
      Loading()
      const result = await aliPay(orderNo, discount)

      setState({
        alipayForm: result,
      })

      setTimeout(() => {
        document.forms[0].submit()
      }, 100)
    } catch (error) {
      Toast('系统异常，请重试')
    } finally {
      Loading.hide()
    }
  }

  const onSubmit = () => {
    if (state.payType === 'alipay') {
      onAlipay()
    } else {
      onWechatPay()
    }
  }

  const stepByStep = async (
    step: 'step1' | 'step2' | 'step3' | 'step4' | 'step5' | 'step6' | 'step7' | 'step8' | 'step9',
  ) => {
    await sleep(1000)

    setState({
      processing: false,
    })

    await sleep(200)

    setState({
      animationTitle: stepsTitle[step],
      steps: step,
      processing: true,
    })
  }

  const runTj = async (v: string) => {
    const [b31, b21, b11] = v.split('')
    const b3 = ['根', '苗', '花', '果']
    const b2 = ['甲', '乙', '丙', '丁', '戊', '己', '庚', '辛', '壬', '癸']
    const b1 = ['子', '丑', '寅', '卯', '辰', '巳', '午', '未', '申', '酉', '戌', '亥']

    setState({
      tjCircle1: 'rotate' + (b1.indexOf(b11) > -1 ? b1.indexOf(b11) : 0),
      tjCircle2: 'rotate' + (b2.indexOf(b21) > -1 ? b2.indexOf(b21) : 0),
      tjCircle3: 'rotate' + (b3.indexOf(b31) > -1 ? b3.indexOf(b31) : 0),
    })

    await sleep(1200)

    setState({
      tjCircleMasker: true,
    })

    await sleep(500)

    setState({
      tjCircleMasker: false,
    })
  }

  const animationSteps = async (userData: any) => {
    setState({
      steps: 'step1',
      animationTitle: stepsTitle['step1'],
      processing: true,
    })

    await sleep(200)

    setState({
      tjPanStep: 'step1',
    })

    await sleep(1000)

    const [b1, b2, b3, b4] = userData.bgp.split('、')

    runTj(b1)
    await stepByStep('step2')

    setState({
      tjPanStep: 'step2',
    })

    await stepByStep('step3')

    runTj(b2)
    await stepByStep('step4')
    await stepByStep('step5')
    runTj(b3)
    await stepByStep('step6')
    await stepByStep('step7')
    runTj(b4)
    await stepByStep('step8')
    await stepByStep('step9')

    await sleep(2000)

    setState({
      processing: false,
      isAnimationEnd: true,
      steps: 'end',
    })

    setInfoInputAnimationEnd('end')
  }

  const getUserData = async () => {
    try {
      if (!orderNo) {
        Toast('地址不正确，请回首页重新测试')
        return
      }

      setState({
        loading: true,
      })

      const result: any = await http.request({
        url: 'result/queryEmotionPre',
        method: 'POST',
        data: {
          productType: '11',
          orderId: orderNo,
          emotionParam: {
            gender,
            name,
            timeOfBirth: birth,
          },
        },
      })

      setState({
        userData: result.body,
      })

      // 动画
      if (getInfoInputAnimationEnd() !== 'end') {
        animationSteps(result.body)
      }
    } catch (e: any) {
    } finally {
      setState({
        loading: false,
      })
    }
  }

  useEffect(() => {
    countdown(60 * 5 - 1)

    getUserData()

    return () => {
      timer && clearTimeout(timer)
    }
  }, [])

  return (
    <Layout className="page-pay" useBottomSafeArea={false}>
      <Layout.Body className="body">
        {state.steps !== 'end' ? (
          <div className="tj-pan">
            <div className="tj-title">{name}命主的命书</div>
            <div
              className={cn('step step1', {
                show: state.tjPanStep === 'step1',
              })}
            >
              <div className="item item1">
                <em>姓名：</em>
                {name}
              </div>
              <div className="item item2">
                <em>性别：</em>
                {gender}
              </div>
              <div className="item item3">
                <em>出生时间：</em>
                {birth}
              </div>
            </div>

            <div
              className={cn('step step2', {
                show: state.tjPanStep === 'step2',
              })}
            >
              <div className="bazi">
                {(state.userData.bgp || '').split('、').map((item: string, index: number) => (
                  <div key={index} className={cn(`b b${index + 1}`)}>
                    {item}
                  </div>
                ))}
              </div>
            </div>

            <div className="tj">
              <div
                className={cn('shanxing', {
                  show: state.tjCircleMasker,
                })}
              >
                <div className="c" />
                <div className="skew" />
              </div>
              <div className={cn('circle circle1', state.tjCircle1)} />
              <div className={cn('circle circle2', state.tjCircle2)} />
              <div className={cn('circle circle3', state.tjCircle3)} />
            </div>

            <div className="process-animation">
              <div className="title">{state.animationTitle}</div>
              <div
                className={cn('process', {
                  start: state.processing,
                  // step2: state.steps === 'step2',
                })}
              >
                {state.processing && <div className="bar" />}
              </div>
            </div>
          </div>
        ) : (
          <div className="wrapper">
            <div className="info-title">
              <div className="tj-title">{name}命主的命书</div>
              <div className="item">
                <em>五行：</em>
                {state.userData.mzgj}
              </div>
              <div className="item">
                <em>命中格局：</em>
                {state.userData.wxys}
              </div>
            </div>

            <div className="payinfo">
              <Card>
                <Report name={name} lock />

                <div className="price">
                  <div className="discount">
                    <span className="original">原价68元</span> <span>限时优惠倒计时</span>
                  </div>
                  <div className="current">
                    <span>限时特价</span>
                    <em>￥9.99</em>
                    <div className="countdown">{getCountDown(state.time)}</div>
                  </div>
                </div>

                <div className="payways">
                  <div
                    className={cn('payway wechat', {
                      checked: state.payType === 'wechat',
                    })}
                    onClick={() => setState({ payType: 'wechat' })}
                  >
                    <div className="icon" />
                    <div className="name">微信</div>
                    <div className="check" />
                  </div>
                  {/* <div
                    className={cn('payway alipay', {
                      checked: state.payType === 'alipay',
                    })}
                    onClick={() => setState({ payType: 'alipay' })}
                  >
                    <div className="icon" />
                    <div className="name">支付宝</div>
                    <div className="check" />
                  </div> */}
                </div>
                <Button className="submit" onClick={onSubmit}>
                  立即解锁报告
                </Button>
              </Card>
            </div>
            <FakeBubble />
          </div>
        )}
        <Footer />
      </Layout.Body>

      <div
        id="alipay_form"
        style={{ display: 'none' }}
        dangerouslySetInnerHTML={{ __html: state.alipayForm as string }}
      />
    </Layout>
  )
}

export default PagePay
