import { Search } from 'auto-libs'
import { Alert, Layout, Loading, Toast } from 'auto-ui'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'
import Button from '../../components/button'
import Card from '../../components/card'
import ContentCard from '../../components/content-card'
import FeedBack from '../../components/feedback'
import Report from '../../components/report'
import http from '../../utils/http'
import { aliPay, wxPay } from '../../utils/pay'
import { delHomePageCouponPop, setXNameTest } from '../../utils/storage'
import { sleep } from '../../utils/time'
import Ad from './components/ad'
import ClassifyHeader from './components/classify-header'
import AdInAll from './imgs/ad-inall.png'
import './style.scss'

export interface IState {
  loading: boolean
  errorMsg: string
  resultData: any
  alipayForm: string
  bName: string
  gName: string
  result: any
}

const PageResult = () => {
  const orderId = Search.get('orderNo') || ''
  const backTo = Search.get('backTo') || ''
  const navigateHook = useNavigate()

  const [state, setState] = useSetState<IState>({
    loading: false,
    errorMsg: '',
    resultData: '',
    alipayForm: '',
    bName: '',
    gName: '',
    result: {},
  })

  let timer: any = null
  let payWay = 'WXPAY'
  let alertCloseHandler: any = null

  const onBackHome = () => {
    if (backTo === 'order') {
      navigateHook('/order', { replace: true })
    } else {
      navigateHook('/', { replace: true })
    }
  }

  const fetchMatchResult = async () => {
    try {
      if (!orderId) {
        Toast('地址不正确，请回首页重新测试')
        return
      }

      setState({
        loading: true,
      })

      await sleep(1500)

      const payResult: any = await http.request({
        url: '/order/queryStatus',
        method: 'POST',
        data: {
          orderId,
        },
      })

      let { payMethod, status } = payResult.body
      if (payResult.code !== '00000000' || status !== 2) {
        payWay = payMethod
        checkPayStatus()
        return
      }

      const result: any = await http.request({
        url: 'result/queryEmotion',
        method: 'POST',
        data: {
          orderId,
        },
      })

      delHomePageCouponPop()

      setXNameTest('done')

      setState({ result: result.body })
    } catch (e: any) {
      setState({ errorMsg: e.message || '系统异常，请重试' })
    } finally {
      setState({
        loading: false,
      })
    }
  }

  const onWechatPay = async () => {
    const result = await wxPay(orderId)
    window.location.href = result
  }

  const onAlipay = async () => {
    const result = await aliPay(orderId)

    setState({ alipayForm: result })

    setTimeout(() => {
      document.forms[0].submit()
    }, 100)
  }

  const toPay = async () => {
    try {
      Loading()
      if (payWay === 'WXPAY') {
        await onWechatPay()
      } else {
        await onAlipay()
      }
    } catch (error: any) {
      Toast(error.message || '系统异常，请重新再试')
    } finally {
      Loading.hide()
    }
  }

  const checkPayStatus = () => {
    const [close] = Alert({
      title: '请确认支付是否完成',
      btnDirection: 'column',
      btns: [
        {
          name: '已完成支付',
          type: 'danger',
          hollow: true,
          onClick: () => {
            fetchMatchResult()
          },
        },
        {
          name: '支付遇到问题，重新支付',
          type: 'danger',
          onClick: () => {
            toPay()
          },
        },
      ],
    })

    alertCloseHandler = close
  }

  useEffect(() => {
    fetchMatchResult()

    return () => {
      timer && clearTimeout(timer)

      alertCloseHandler && alertCloseHandler()
    }
  }, [])

  return (
    <Layout className="page-result" useBottomSafeArea={false}>
      <Layout.Body className="body" loading={state.loading} errorInfo={state.errorMsg}>
        <div className="scroller">
          <div className="info-title">
            <div className="tj-title">{state.result.name}命主的命书</div>
            <div className="item">
              <em>五行：</em>
              {state.result.mzgj}
            </div>
            <div className="item">
              <em>命中格局：</em>
              {state.result.wxys}
            </div>
          </div>
          <Card>
            <Report name={state.result.name} lock={false} />
          </Card>
          <Card>
            <div className="names">
              <div className="item">性别：{state.result.gender}</div>
              <div className="item">公历：{state.result.gregorian}</div>
              <div className="item">农历：{state.result.lunar}</div>
              <div className="item">属相：{state.result.shengXiao}</div>
            </div>

            <ContentCard title="情感运势分析">{state.result.qgys}</ContentCard>
            <ContentCard title="情感运势主星">{state.result.qgyszx}</ContentCard>
            <ContentCard title="桃花分析">{state.result.thfx}</ContentCard>
            <ContentCard title="掌控欲">{state.result.zky}</ContentCard>
            <ContentCard title="夫妻沟通">{state.result.fqgt}</ContentCard>
            <ContentCard title="情趣指数">{state.result.qgzs}</ContentCard>
            <ContentCard title="你的先天姻缘情况如何">{state.result.xtyy}</ContentCard>
            <ContentCard title="你的正缘伴侣是怎样">{state.result.zybl}</ContentCard>
            <ContentCard title="2023姻缘分析">{state.result.yyfx}</ContentCard>
            <ContentCard title="2023姻缘走势">{state.result.yyzs}</ContentCard>
            <ContentCard title="财富运势分析">{state.result.cfysfx}</ContentCard>
            <ContentCard title="财运主星">{state.result.cyzx}</ContentCard>
            <ContentCard title="赚钱能力">{state.result.zqnl}</ContentCard>
            <ContentCard title="蓄财能力">{state.result.ccnl}</ContentCard>
            <ContentCard title="正财运">{state.result.zcy}</ContentCard>
            <ContentCard title="偏财运">{state.result.pcy}</ContentCard>
            <ContentCard title="2023财运分析">{state.result.cyfs}</ContentCard>
          </Card>
          <div className="ads">
            <ClassifyHeader title="更多推荐" />

            <a className="ad inall" href="https://inall.ijianshui.com">
              <img src={AdInAll} />
            </a>

            <Ad
              list={[
                {
                  name: '八字合婚',
                  imgUrl: require('./imgs/ln/hh.png'),
                  url: 'https://hehun.ijianshui.com?channel=xvfd$71',
                },
                {
                  name: '姓名配对',
                  imgUrl: require('./imgs/ln/xm.png'),
                  url: 'https://l1.ijianshui.com/?channel=dmmy$73',
                },
                {
                  name: '姻缘测试',
                  imgUrl: require('./imgs/mj/yy.png'),
                  url: 'https://yanyuan.ijianshui.com/?channel=386v$74',
                },
                {
                  name: '兔年运势',
                  imgUrl: require('./imgs/mj/qg.png'),
                  url: 'https://qinggan.ijianshui.com?channel=athz$75',
                },
                {
                  name: '智商测试',
                  imgUrl: require('./imgs/mj/iq.png'),
                  url: 'https://iqt.ijianshui.com/?channel=b4vk$76',
                },
                {
                  name: 'MBTI',
                  imgUrl: require('./imgs/mj/mbti.png'),
                  url: 'https://mbti.ijianshui.com/?channel=yfzh$77',
                },
              ]}
            />
          </div>
        </div>

        <Button className="submit" onClick={onBackHome}>
          再测试一次
        </Button>

        <FeedBack orderNo={orderId} />
      </Layout.Body>
      <div
        id="alipay_form"
        style={{ display: 'none' }}
        dangerouslySetInnerHTML={{ __html: state.alipayForm }}
      />
    </Layout>
  )
}

export default PageResult
