import './style.scss'

export default function Footer() {
  return (
    <div className="com-footer">
      <h2>资深大师 匠心巨制</h2>
      <div className="cx" />
      <div className="company">上海简水网络科技有限公司</div>
      <div className="ba">沪ICP备18044360号-2</div>
    </div>
  )
}
