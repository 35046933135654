import cn from 'classnames'
import React from 'react'
import './style.scss'

interface IProps {
  children: React.ReactNode
  className?: string
  onClick: () => void
  sharke?: boolean
}

export default function Button(props: IProps) {
  const { children, className, sharke = true } = props

  return (
    <div
      className={cn('com-btn', className, {
        animation: sharke,
      })}
      onClick={props.onClick}
    >
      <div className="btn">{children}</div>
    </div>
  )
}
