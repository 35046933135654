import './style.scss'

interface IProps {
  title: string
  className?: string
}

export default function ClassifyHeader(props: IProps) {
  return (
    <div className={`com-classify-header ${props.className ? props.className : ''}`}>
      <div className="icon" />
      <div className="title">{props.title}</div>
    </div>
  )
}
