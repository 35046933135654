import React from 'react'
import './style.scss'

export default function FakeCommentLite() {
  const data = [
    {
      time: '5秒前',
      name: '*斌',
    },
    {
      time: '15秒前',
      name: '刘*云',
    },
    {
      time: '37秒前',
      name: '杨*元',
    },
    {
      time: '45秒前',
      name: '许*银',
    },
    {
      time: '48秒前',
      name: '付*成',
    },
    {
      time: '1分钟前',
      name: '刘*飞',
    },
    {
      time: '1分钟前',
      name: '张*浩',
    },
    {
      time: '1分钟前',
      name: '沈*涛',
    },
    {
      time: '1分钟前',
      name: '赵*达',
    },
    {
      time: '2分钟前',
      name: '李*',
    },
    {
      time: '2分钟前',
      name: '张*剑',
    },
    {
      time: '3分钟前',
      name: '李*育',
    },
    {
      time: '3分钟前',
      name: '*敏',
    },
    {
      time: '3分钟前',
      name: '周*超',
    },
    {
      time: '3分钟前',
      name: '*超',
    },
    {
      time: '4分钟前',
      name: '张*超',
    },
    {
      time: '4分钟前',
      name: '张*澄',
    },
    {
      time: '4分钟前',
      name: '孙*文',
    },
    {
      time: '4分钟前',
      name: '邵*宏',
    },
    {
      time: '5分钟前',
      name: '周*和',
    },
    {
      time: '5分钟前',
      name: '陈*予',
    },
    {
      time: '5分钟前',
      name: '王*旭',
    },
    {
      time: '5分钟前',
      name: '赵*',
    },
    {
      time: '5分钟前',
      name: '*超',
    },
  ]

  return (
    <div className="com-fake-comment-lite">
      <div className="marquee">
        <div className="inner">
          {data.map((item: any, index: number) => (
            <div className="item" key={index}>
              {item.name}
              <br />
              <em>{item.time}</em>购买了情感报告
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
