import { Button, Dialog, Loading, Toast } from 'auto-ui'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import http from '../../../../utils/http'
import { aliPay, wxPay } from '../../../../utils/pay'
import { delHomePageCouponPop } from '../../../../utils/storage'
import { getCountDown } from '../../../../utils/time'
import './style.scss'

interface IProps {
  orderNo: string
  visible: boolean
  onPopClose: () => void
}

let CountDownTime = 5 * 60 - 1

export default function CouponPop(props: IProps) {
  const { orderNo, visible, onPopClose } = props
  const [time, setTime] = useState(CountDownTime)
  const [alipayForm, setAlipayForm] = useState<any>(null)
  const navigateHook = useNavigate()
  let payWay = 'WXPAY'
  let timer: any = null

  const countdown = (time: number) => {
    let _time = time
    timer = setTimeout(() => {
      --_time
      if (_time === 0) {
        clearTimeout(_time)
        return
      }
      setTime(time)
      countdown(_time)
    }, 1000)
  }

  const onWechatPay = async (discount?: string) => {
    try {
      Loading()
      const result = await wxPay(orderNo, discount)
      window.location.href = result
    } catch (error: any) {
      Toast(error.message || '系统异常，请重试')
    } finally {
      Loading.hide()
    }
  }

  const onAlipay = async (discount?: string) => {
    try {
      Loading()
      const result = await aliPay(orderNo, discount)

      setAlipayForm(result)

      setTimeout(() => {
        document.forms[0].submit()
      }, 100)
    } catch (error) {
      Toast('系统异常，请重试')
    } finally {
      Loading.hide()
    }
  }

  const toPay = async () => {
    try {
      Loading()

      onPopClose && onPopClose()

      const payResult: any = await http.request({
        url: '/order/queryStatus',
        method: 'POST',
        data: {
          orderId: orderNo,
        },
      })

      let { payMethod, status } = payResult.body
      if (payResult.code !== '00000000' || status !== 2) {
        payWay = payMethod

        if (!payMethod || payWay === 'WXPAY') {
          await onWechatPay('1')
        } else {
          await onAlipay('1')
        }
      } else {
        navigateHook(`/result?orderNo=${orderNo}`)
      }
    } catch (error: any) {
      Toast(error.message || '系统异常，请重新再试')
    } finally {
      Loading.hide()
    }
  }

  const onRefuse = async () => {
    try {
      onPopClose && onPopClose()

      delHomePageCouponPop()

      const payResult: any = await http.request({
        url: '/order/queryStatus',
        method: 'POST',
        data: {
          orderId: orderNo,
        },
      })

      let { status } = payResult.body
      if (!(payResult.code !== '00000000' || status !== 2)) {
        navigateHook(`/result?orderNo=${orderNo}`)
      }
    } catch (error) {}
  }

  useEffect(() => {
    countdown(CountDownTime)

    // if (d === 'd') {
    //   // 说明是从取消支付中来的
    //   fetchMatchResult()
    // }

    return () => {
      CountDownTime = 5 * 60 * 1
      timer && clearTimeout(timer)
    }
  }, [])

  return (
    <Dialog className="pop-red-pack" visible={visible} height="525px">
      <div className="inner">
        <h1>恭喜您获得红包！</h1>
        <h2>
          3.00<sup>元</sup>
        </h2>
        <h6>查看配对测试结果立减</h6>

        <div className="countdown">{getCountDown(time)}后失效</div>

        <div className="btns">
          <Button className="btn ok" onClick={toPay}>
            立即使用
          </Button>
          <Button className="btn cancel" onClick={onRefuse} hollow>
            残忍拒绝
          </Button>
        </div>
      </div>

      <div
        id="alipay_form"
        style={{ display: 'none' }}
        dangerouslySetInnerHTML={{ __html: alipayForm }}
      />
    </Dialog>
  )
}
